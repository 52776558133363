.check-box {
  cursor: pointer;
  display: flex;
  flex-direction: row;

  .check-box-icon-container {
    border-radius: 2px;
    padding: 6px;

    &:not(.check-box-disabled):hover {
      background-color: $lightGray300;
    }
  }
}

.check-box-disabled {
  cursor: not-allowed;
}
