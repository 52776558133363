.button-link {
  align-items: center;
  all: unset;
  cursor: pointer;
  display: flex;

  div {
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 150%;
    margin-left: 2px;
    transition: all 0.2s ease-in-out;

    @media (max-width: $mobile-breakpoint) {
      font-size: 18px !important;
    }
  }

  &:enabled:hover {
    .button-link-text,
    div {
      color: $advanceBlue500 !important;
    }

    div {
      margin-left: 10px;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}
