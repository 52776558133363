.cta-center {
  text-align: center;
}


.cta-left {
  text-align: center;

  @media (min-width: $desktop-start) {
    display: flex;
    gap: 0 135px;
    justify-content: center;
    text-align: left;

    .text {
      max-width: 591px;
      width: 100%;
    }

    .btn {
      align-self: center;
    }
  }
}

.cta-container {
  display: grid;
  gap: 0 $dsp64;
  grid-template-areas: 'graphic content';
  grid-template-columns: 203px 1fr;
  grid-template-rows: none;

  .btn-container {
    display: flex;
  }

  .graphic {
    grid-area: graphic;
  }

  .content {
    grid-area: content;
  }

  @media (max-width: $mobile-breakpoint) { 
    gap: $msp40 0;
    grid-template-areas:
      'content'
      'graphic';
    grid-template-columns: none;
    grid-template-rows: 1fr 201px;
    justify-items: center;
  }
}
