.about-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 104px 24px;

  @media (max-width: $mobile-breakpoint) {
    padding: 40px 24px;
    gap: 24px;
  }

  .about-us-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: $max-container-width;
    overflow-x: visible;
    width: 100%;
    gap: 32px;

    @media (max-width: 750px) {
      flex-direction: column;
    }

    img {
      flex-shrink: 2;
      max-width: 452px;
      max-height: 424px;
      object-fit: scale-down;

      @media (max-width: 1296px) {
        overflow-x: hidden;
      }
  
      @media (max-width: $max-container-width) {
        min-width: 200px;
      }

      @media (max-width: $mobile-breakpoint) {
        height: 328px;
      }
    }
    
    .about-us-text {
      display:flex;
      flex-direction: column;
      gap: 12px;
      max-width: 658px;
      flex-shrink: 0;

      @media (max-width: $max-container-width) {
        flex-shrink: 2;
        min-width: 200px;
      }

      @media (max-width: 750px) {
        max-width: 100%;
        flex-direction: column;
      }

      @media (max-width: $mobile-breakpoint) {
        gap: 12px;
      }
    }
  }
}

.title-narrow {
  letter-spacing: -0.5px;
}

.colored-grid-item-container{
  display: flex;
  gap: 16px;
  width: 1044px;

  @media (max-width: 1100px) {
    width: 100%;
  }

  @media (max-width: 670px) {
    flex-direction: column;
  }

}

.outer-colored-grid-item-container{
  max-width: 1100px;
}
