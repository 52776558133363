$text-icon-group-breakpoint: 770px;

.text-icon-group {
  display: flex;
  gap: 32px;
  justify-content: space-between;

  &.text-position-right {
    flex-direction: row-reverse;
  }

  .column-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  span,
  a {
    display: block;
    max-width: 335px;
  }

  img {
    height: auto;
    max-width: 447px;
    width: 100%;
  }

  @media (max-width: $text-icon-group-breakpoint) {
    flex-direction: column;

    &.text-position-right {
      flex-direction: column;
    }

    .column-item {
      width: 100%;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    img {
      max-width: 327px;
    }
  }
}

.text-icon-group-story {
  gap: 32px, 48px;

  .text-container {
    flex-shrink: 2;
  }

  .story-title span,
  .story-container {
    max-width: 528px !important;
    width: 100% !important;
  }

  .text-group {
    span,
    a {
      max-width: 448px !important;
      text-align: left;
      width: 100% !important;
    }
  }

  .image-container {
    flex-shrink: 1;

    img {
      max-width: 544px;
    }
  }

  @media (max-width: $text-icon-group-breakpoint) {
    .text-group span {
      width: 100% !important;
    }
  }
}
