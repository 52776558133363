.testimonial {
  margin-top: 56px;

  @media (max-width: $mobile-breakpoint) {
    margin-top: 40px;
   }
   
  .testimonial-item-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 56px 40px;
    background-color: $lightGray100;
    border-radius: 8px;
    border: 3px solid $lightGray300;
    gap: 32px;

    .testimonial-person-container {
      display: flex;
      align-items: center;
      gap: 8px;

      @media (max-width: $mobile-breakpoint) {
       flex-direction: column;
       align-items: start;
       padding: 0;
       gap: 12px;
      }
    }

    img {
      height: 72px;
      width: 72px;
      border-radius: 100%;
    }

    .testimonial-description-mobile {
      @media (max-width: $mobile-breakpoint) {
        display: flex;
      }
    }

    @media (max-width: $mobile-breakpoint) {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
    }
  }

  .testimonial-dots {
    display: flex;
    justify-content: center;
    align-items: center;

    .testimonial-dot {
      background-color: $lightGray300;
      border-radius: 50%;
      height: 12px;
      width: 12px;
      cursor: pointer;
    }

    .testimonial-controls {
      cursor: pointer !important;
      margin: 0 8px;
    }

    .active {
      background-color: $advanceBlue400;
    }
  }
}
