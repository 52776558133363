.mobile-only {
  @media (min-width: $desktop-start) {
    display: none !important;
  }
}

.desktop-only {
  @media (max-width: $mobile-breakpoint) {
    display: none !important;
  }
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}
