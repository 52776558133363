@font-face {
  font-family: 'Larsseit-Bold';
  src: url('~@public-web/assets/fonts/Larsseit-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Larsseit-Light';
  src: url('~@public-web/assets/fonts/Larsseit-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Larsseit-Medium';
  src: url('~@public-web/assets/fonts/Larsseit-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Larsseit';
  src: url('~@public-web/assets/fonts/Larsseit.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansDisplayBold';
  src: url('~@public-web/assets/fonts/NotoSansDisplayBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansBold';
  src: url('~@public-web/assets/fonts/NotoSansBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansMedium';
  src: url('~@public-web/assets/fonts/NotoSansMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansRegular';
  src: url('~@public-web/assets/fonts/NotoSansRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansSemiBold';
  src: url('~@public-web/assets/fonts/NotoSansBold.ttf') format('truetype');
}

.h1 {
  font-family: 'Larsseit-Bold';
  font-size: 61px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 32px;
  }
}

.h2 {
  font-family: 'Larsseit-Bold';
  font-size: 49px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 29px;
  }
}

.h3 {
  font-family: 'Larsseit-Bold';
  font-size: 39px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 26px;
  }
}

.h4 {
  font-family: 'Larsseit-Medium';
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.h5 {
  font-family: 'Larsseit-Medium';
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 20px;
  }
}

.h6 {
  font-family: 'Larsseit-Medium';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 18px;
  }
}

.large {
  font-family: 'Larsseit';
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 18px;
  }
}

.base {
  font-family: 'Larsseit';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.small {
  font-family: 'Larsseit';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 14px;
  }
}

.smallest {
  font-family: 'Larsseit';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media (max-width: $mobile-breakpoint) {
    font-size: 13px;
  }
}

.displayH1 {
  font-family: 'NotoSansDisplayBold', $webfonts, sans-serif;
  font-size: 11.25rem;
  line-height: 13rem;
  letter-spacing: -0.5625rem;
};

.displayH2 {
  font-family: 'NotoSansDisplayBold', $webfonts, sans-serif;
  font-size: 7rem;
  line-height: 8.25rem;
  letter-spacing: -0.35rem;
};

.displayH3 {
  font-family: 'NotoSansDisplayBold', $webfonts, sans-serif;
  font-size: 4.25rem;
  line-height: 4.875rem;
  letter-spacing: -0.1275rem;

  @media (max-width: $mobile-breakpoint) {
    font-size: 2.75rem;
    line-height: 3.25rem;
    letter-spacing: -0.055rem;
  }
};

.labelBold {
  font-family: 'NotoSansBold', $webfonts, sans-serif;
  font-size: 0.875rem;
  line-height: 1.75rem;
  letter-spacing: 0.035rem;
};

.labelBase {
  font-family: 'NotoSansMedium', $webfonts, sans-serif;
  font-size: 0.875rem;
  line-height: 1.75rem;
  letter-spacing: 0.035rem;
};

.titleH4 {
  font-family: 'NotoSansBold', $webfonts, sans-serif;
  font-size: 2.75rem;
  line-height: 3.25rem;
  letter-spacing: -0.055rem;

  @media (max-width: $mobile-breakpoint) {
    font-size: 1.75rem;
    line-height: 2rem;
    letter-spacing: -0.035rem;
  }
};

.titleSubheader{
  font-family: 'NotoSansBold', $webfonts, sans-serif;
  font-size: 1.75rem;
  line-height: 2rem;
  letter-spacing: -0.035rem;

  @media (max-width: $mobile-breakpoint) {
    font-family: 'NotoSansSemiBold';
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: -0.025;
  }
};

.titleBase{
  font-family: 'NotoSansSemiBold', $webfonts, sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: -0.025;
};

.bodyBold{
  font-family: 'NotoSansBold', $webfonts, sans-serif;
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing: 0.02rem;
};

.bodyBase{
  font-family: 'NotoSansRegular', $webfonts, sans-serif;
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing: 0.02rem;
};

.bodyBaseFallback{
  font-family: 'NotoSansRegular', $webfonts, sans-serif;
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing: 0.02rem;
  color: red
};
