.pull-quote {
  margin-block: 0 !important;
  margin-bottom: -55px !important;
  margin-inline: 0 !important;
  quotes: '\201C''\201D''\2018''\2019';
  text-align: center;
  
  &::before {
    color: $indigo200;
    content: open-quote;
    font-family: Larsseit;
    font-size: 240px;
    line-height: 0.1em;
    margin-right: -65px;
    vertical-align: -0.4em;

    @media (max-width: $mobile-breakpoint) {
      font-size: 160px;
      margin-right: -45px;
    }
  }

  &::after {
    color: $indigo200;
    content: close-quote;
    font-family: Larsseit;
    font-size: 240px;
    line-height: 0.1em;
    margin-left: -10px;
    vertical-align: -0.6em;

    @media (max-width: $mobile-breakpoint) {
      font-size: 160px;
      margin-left: -5px;
    }
  }
}
