.drop-down-container {
  position: relative;
}

.drop-down {
  @extend .base;
  background-color: $lightGray100;
  border: 1px solid $lightGray400;
  border-radius: 4px;
  box-sizing: border-box;
  color: $gray600;
  outline: none;
  padding: 12px;
  width: 100%;

  &::placeholder {
    color: $lightGray500;
    opacity: 1;
  }

  &:not(:focus) {
    cursor: pointer; 
  }
  
  &:not(:placeholder-shown):not(:focus) {
    border: 1px solid $lightGray100;
  }
}

.drop-down-options-container {
  background-color: $lightGray100;
  border: 1px solid $lightGray400;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 110px;
  min-height: 37px;
  overflow-y: scroll;
  position: absolute;
  top: 54px;
  width: 100%;
  z-index: 9999;

  .drop-down-option {
    cursor: pointer;
    padding: 8px;

    &:hover {
      background-color: $lightGray300;
    }
  }
}

.drop-down-error {
  border: 1px solid $error400;
}

.drop-down-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
