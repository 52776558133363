.statistic-card {
  text-align: center;
}

.category-card {
  text-align: center;

  @media (max-width: $mobile-breakpoint) {
    display: flex;
    text-align: left;
  }

  img {
    border-radius: 50%;
    height: 120px;
    width: 120px;

    @media (max-width: $mobile-breakpoint) {
      height: 96px;
      width: 96px;
    }
  }
}

.person-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 250px;
  height: 266px;
  border-radius: 8px;
  border: 2px solid $lightGray300;
  background-color: $white;


  @media (max-width: $mobile-breakpoint) {
    width: 100%;
    align-self: stretch;
  }

  img {
    height: 80px;
    width: 80px;
  }

  .image-container--big {
    border-radius: 50%;
    height: 140px;
    width: 140px;

    @media (max-width: $mobile-breakpoint) {
      height: 123px;
      width: 123px;
    }
  }

  .image-container--small {
    border-radius: 50%;
    height: 120px;
    width: 120px;

    @media (max-width: $mobile-breakpoint) {
      height: 96px;
      width: 96px;
    }
  }
}

.list-item-card {
  display: flex;
  
  img {
    height: 64px;
    width: 64px;

    @media (max-width: $mobile-breakpoint) {
      height: 40px;
      width: 40px;
    }
  }
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.center-mobile{
  @media (max-width: $mobile-breakpoint) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
