.text-field {
  @extend .bodyBase;
  background-color: $lightGray100;
  border: 1px solid $lightGray400;
  border-radius: 4px;
  box-sizing: border-box;
  color: $gray600;
  outline: none;
  padding: 12px;
  width: 100%;

  &:focus {
    border: 1px solid $advanceBlue400;
  }

  &::placeholder {
    color: $lightGray500;
    opacity: 1;
  }

  &:read-only {
    background-color: $white;
    border: 1px solid $white;
    padding: 0;
  }

  &:not(:placeholder-shown):not(:focus) {
    border: 1px solid $lightGray100;
  }
}

.text-field-error {
  border: 1px solid $error400 !important;
}

.text-field-success {
  border: 1px solid $success600 !important; 
}

.text-field-default-form {
  border: 1px solid $lightGray400 !important; 
}

.text-field-prefix {
  @extend .bodyBase;
  padding: 12px;
  display: flex;
  background-color: $lightGray100;
  border: 1px solid $lightGray400;
  border-radius: 4px;
  color: $gray600;
  outline: none;
  width: 100%;

  &:focus {
    border: 1px solid $advanceBlue400;
  }

  &::placeholder {
    color: $lightGray500;
    opacity: 1;
  }

  input {
    @extend .bodyBase;
    box-sizing: border-box;
    padding: 0 0 0 4px;
    background-color: transparent !important;
    border: transparent !important;
    outline: none;
    width: 100%;
      &:focus {
        border: transparent !important;
      }
      &::placeholder {
        color: $lightGray500;
        opacity: 1;
      }
      &:not(:placeholder-shown):not(:focus) {
        border: 1px solid $lightGray100;
      }
  }
  
}
