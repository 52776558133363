.search-input {
  @extend .large;
  background-color: $white;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  color: $gray600;
  outline: none;
  padding: 16px 24px;
  width: calc(100% - 48px);

  @media (max-width: $mobile-breakpoint) {
    padding: 12px 20px;
    width: calc(100% - 40px);
  }

  &::placeholder {
    color: $lightGray600;
    opacity: 1;
  }
}
