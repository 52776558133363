.lead-gen-form {
  .input-row {
    display: flex;
  
    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }
  
    .input-col {
      flex: 1;
    }
  }

  .button-container button {
    @media (max-width: $mobile-breakpoint) {
      box-sizing: border-box;
      width: 100%;
    }
  }
}
