.accordion-title {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .accordion-icon {
    transition: all 0.2s;
  }
}

.accordion-item {
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
