#snackbar-container,
#__next>div:last-child {

  position: fixed;

  // Temporary selector (https://github.com/arnnis/react-native-toast-notifications/pull/142) 
  @media (min-width: 769px) {
    width: auto !important;
    bottom: 24px !important;
    right: 24px;
  }

  @media (max-width: 481px)  {
    bottom: 24px !important;
    right: 24px;
    left: 24px;
  }
}

.custom-chatbot-frame {
  @media (min-width: 769px) {
    max-height: 570px !important;
  }
}

.custom-bullet-points {
  list-style-type: none;
  padding: 0px;
  padding-left: 40px;

  li:before {
    content: "•";
    font-size: 12pt;
    padding-right: 10px;
  }
}
