.slider-tooltip {
  background-color: $information200;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 8px 24px;
  position: relative;
  text-align: center;

  .slider-tooltip-arrow {
    bottom: -17px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}
