$person-card-md-breakpoint: 785px;

.grid-container {
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 40px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  justify-content: center;
  width: 100%;

  @media (max-width: $mobile-breakpoint) { 
    grid-row-gap: 32px;
    grid-template-columns: repeat(1, 1fr);
  }

  div {
    grid-column: span 4;

    @media (max-width: $mobile-breakpoint) {
      grid-column: span 1;
    }
  }
}

.person-card-grid--big {
  @extend .grid-container;

  @media (max-width: $mobile-breakpoint) { 
    grid-row-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  div {
    grid-column: span 3;

    @media (max-width: $person-card-md-breakpoint) {
      grid-column: span 6;
    }

    @media (max-width: $mobile-breakpoint) {
      grid-column: span 2;
    }
  }
}

.person-card-grid--small {
  @extend .person-card-grid--big;

  div {
    grid-column: span 3;
    
    @media (max-width: $mobile-breakpoint) {
      grid-column: span 1;
    }
  }
}

.grid-max-width-wrapper {
  max-width: $max-container-width;
  margin: 0 auto;
  display: grid;
  gap: 48px;

  @media (max-width: $mobile-breakpoint) {
    gap: 32px;
  }

  .icon-title {
    @media (max-width: $mobile-breakpoint) {
      padding-bottom: 16px;
    }
  }
}

.grid-item-image-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: $mobile-breakpoint) {
    gap: 12px;
  }

  img {
    max-width: 337px;
    height: 232px;
    border-radius: 8px;

    @media (max-width: $mobile-breakpoint) {
      width: 100%;
      padding-bottom: 4px;
    }
  }

  button {
    max-width: fit-content;

    @media (max-width: $mobile-breakpoint) {
        width: 100%;
        max-width: 100%;
    }
  }

  .inner-grid-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;;
    
    img {
      padding-bottom: 4px;
      
      @media (max-width: $mobile-breakpoint) {
        margin: 0 auto;
    }
    }
  }

}

.grid-item-icon-container {
  display: flex;
  flex-direction: column;
  grid-column: span 6 !important;
  gap: 12px;
  padding: 40px 24px;
  border-radius: 16px;
  text-align: left;
  white-space: pre-wrap;
  
  @media (max-width: $mobile-breakpoint) {
    grid-column: span 1;
  }

  .icon-container {
    width: 64px;
    height: 64px;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 4px;
  }
}

.grid-item-icon-container-large {
  max-width: 514px;
}

.grid-image-container {
  @media (max-width: $mobile-breakpoint) {
    width: 100%;
    height: 100%;
  }
}

.grid-image {
  display: block;
  margin: auto;
  width: 100%;

  @media (max-width: $mobile-breakpoint) {
    width: 312px;
    height: 232px;
  }
}

.gain-team-insights {
  width: 64%;
  display: block;
  margin: auto;


  @media (max-width: $mobile-breakpoint) {
    max-width: 216px;
    max-height: 145px;
    margin-top: 20px !important;
    margin-bottom: 40px !important;
  }
}

.boost-employee-wellness {
  width: 73%;
  display: block;
  margin: auto;

  @media (max-width: $mobile-breakpoint) {
    max-width: 246px;
    max-height: 178px;
    margin-top: 10px !important;
    margin-bottom: 30px !important;
  }
}

.focus-on-what-matters {
  width: 100%;
  display: block;
  margin: auto;
}
