$features-sm-breakpoint: 766px;
$features-lg-start: 767px;

@keyframes feature-highlight-progress {
  0% {
    width: 0;
  }

  100% { 
    width: 100%;
  } 
}

.feature-highlights-container {
  align-items: center;
  background-color: $advanceBlue600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .swiper-container {
    height: 100%;
    max-height: 100vh;
    max-width: 100%;
    min-height: 0;
    min-width: 0;
    width: 100%;
  }

  .swiper-slide {
    flex-grow: 1;
  }

  .feature-highlights {
    display: flex;
    max-width: $max-container-width;
    width: 100%;

    @media (max-width: $features-sm-breakpoint) {
      flex-direction: column-reverse;
    }
  
    .feature-highlights-image-container {
      flex-grow: 1;
      position: relative;

      @media (max-width: $features-sm-breakpoint) {
        height: 360px;
        margin-left: 24px;
        margin-right: 24px;
      }

      @media (min-width: $features-lg-start) {
        width: 500px;
      }
  
      img {
        height: 100%;
        position: absolute;
        width: 100%;
      }
    }
  
    .feature-highlights-content-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-height: 500px;
      width: 450px;

      @media (max-width: $features-sm-breakpoint) {
        min-height: auto;
        padding-left: 24px;
        width: auto;
      }
  
      .feature-highlight-item {
        background-color: $transparent;
        border-radius: 0 0 8px 8px;
        flex-grow: 1;
        transition: background-color 0.2s ease-in-out;
      }
    
      .feature-highlight-item-active {
        background-color: $advanceBlue500;
      }
  
      .feature-highlight-progress {
        background-color: $advanceBlue300;
        height: 4px;
        width: 0;
      }
    
      .feature-highlight-progress-animation {
        animation: feature-highlight-progress 10s;
        animation-timing-function: linear;
      }
    
      .feature-highlight-item-content {
        padding: 24px 24px 32px;
      }
    }
  }
}

.features-sm-only {
  @media (min-width: $features-lg-start) {
    display: none !important;
  }
}

.features-lg-only {
  @media (max-width: $features-sm-breakpoint) {
    display: none !important;
  }
}
