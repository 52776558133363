.success {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  img: {
    height: auto;
    max-width: 368px;
  }

  @media (max-width: $mobile-breakpoint) {
    img: {
      max-width: 200px;
    }
  }
}
