$mobile-breakpoint: 575px;
$desktop-start: 576px;

$max-container-width: 1080px;

$webfonts: 'Helvetica', 'Arial', 'system-ui';

$advanceBlue100: #f5fbff;
$advanceBlue200: #e6f4ff;
$advanceBlue300: #85cbff;
$advanceBlue400: #0088ed;
$advanceBlue500: #0069b8;
$advanceBlue600: #004c85;
$mint100: #f7fdfb;
$mint200: #e3f7f2;
$mint300: #7eddc2;
$mint400: #34c39b;
$mint500: #29997a;
$mint600: #1e715a;
$indigo100: #f6f8fe;
$indigo200: #e9edfc;
$indigo300: #99acf0;
$indigo400: #3f63e2;
$indigo500: #1f46d0;
$indigo600: #1937a4;
$information100: #f8f7fc;
$information200: #eeecf8;
$information300: #9b91d9;
$information400: #796bcc;
$information500: #5745bf;
$information600: #44369b;
$warning100: #fefaf6;
$warning200: #fbeddf;
$warning300: #f2bd8d;
$warning400: #eda461;
$warning500: #e88831;
$warning600: #ce6f17;
$success100: #f8fcfb;
$success200: #e6f5f1;
$success300: #99d6c7;
$success400: #50b89f;
$success500: #3e9883;
$success600: #2f7464;
$error100: #fff5f7;
$error200: #ffdbe1;
$error300: #ff2449;
$error400: #bb0020;
$error500: #8a0018;
$error600: #57000f;
$gray100: #b6c4ce;
$gray200: #97abba;
$gray300: #7892a5;
$gray400: #5f7a8e;
$gray500: #3d4f5c;
$gray600: #29353d;
$lightGray100: #f9fafb;
$lightGray200: #f3f5f7;
$lightGray300: #e3e9ed;
$lightGray400: #d4dce2;
$lightGray500: #c5d0d8;
$lightGray600: #b6c4ce;
$white: #fff;
$black: #000;
$transparent: transparent;

$dsp112: 112px;
$dsp96: 96px;
$dsp80: 80px;
$dsp64: 64px;
$dsp56: 56px;
$dsp48: 48px;
$dsp40: 40px;
$dsp32: 32px;
$dsp24: 24px;
$dsp16: 16px;
$dsp8: 8px;

$msp96: 96px;
$msp80: 80px;
$msp64: 64px;
$msp56: 56px;
$msp48: 48px;
$msp40: 40px;
$msp32: 32px;
$msp24: 24px;
$msp20: 20px;
$msp12: 12px;
$msp6: 6px;

:export {
  advanceBlue100: $advanceBlue100;
  advanceBlue200: $advanceBlue200;
  advanceBlue300: $advanceBlue300;
  advanceBlue400: $advanceBlue400;
  advanceBlue500: $advanceBlue500;
  advanceBlue600: $advanceBlue600;
  black: $black;
  desktopStart: $desktop-start;
  dsp112: $dsp112;
  dsp16: $dsp16;
  dsp24: $dsp24;
  dsp32: $dsp32;
  dsp40: $dsp40;
  dsp48: $dsp48;
  dsp56: $dsp56;
  dsp64: $dsp64;
  dsp8: $dsp8;
  dsp80: $dsp80;
  dsp96: $dsp96;
  error100: $error100;
  error200: $error200;
  error300: $error300;
  error400: $error400;
  error500: $error500;
  error600: $error600;
  gray100: $gray100;
  gray200: $gray200;
  gray300: $gray300;
  gray400: $gray400;
  gray500: $gray500;
  gray600: $gray600;
  indigo100: $indigo100;
  indigo200: $indigo200;
  indigo300: $indigo300;
  indigo400: $indigo400;
  indigo500: $indigo500;
  indigo600: $indigo600;
  information100: $information100;
  information200: $information200;
  information300: $information300;
  information400: $information400;
  information500: $information500;
  information600: $information600;
  lightGray100: $lightGray100;
  lightGray200: $lightGray200;
  lightGray300: $lightGray300;
  lightGray400: $lightGray400;
  lightGray500: $lightGray500;
  lightGray600: $lightGray600;
  maxContainerWidth: $max-container-width;
  mint100: $mint100;
  mint200: $mint200;
  mint300: $mint300;
  mint400: $mint400;
  mint500: $mint500;
  mint600: $mint600;
  mobileBreakpoint: $mobile-breakpoint;
  msp12: $msp12;
  msp20: $msp20;
  msp24: $msp24;
  msp32: $msp32;
  msp40: $msp40;
  msp48: $msp48;
  msp56: $msp56;
  msp6: $msp6;
  msp64: $msp64;
  msp80: $msp80;
  msp96: $msp96;
  success100: $success100;
  success200: $success200;
  success300: $success300;
  success400: $success400;
  success500: $success500;
  success600: $success600;
  transparent: $transparent;
  warning100: $warning100;
  warning200: $warning200;
  warning300: $warning300;
  warning400: $warning400;
  warning500: $warning500;
  warning600: $warning600;
  white: $white;
}
