.row {
  display: flex;
  flex-wrap: wrap;

  .row-item {
    width: 33.33%;

    @media (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }
}
