.link--dark {
  color: $advanceBlue300 !important;
  text-decoration: underline;

  &:hover {
    color: $advanceBlue400 !important;
  }
}

.link--light {
  color: $advanceBlue400 !important;
  text-decoration: underline;

  &:hover {
    color: $advanceBlue500 !important;
  }
}
