.section {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.section > div {
  box-sizing: border-box;
  padding: 0 $dsp24;
  width: 100%;

  &.narrow {
    max-width: 976px;
  }

  &.wide {
    max-width: $max-container-width;
  }
}
  
.section-title {
  text-align: center;
}

.mobile-subheader-left {
  text-align: left;
  font-family: 'NotoSansBold', $webfonts, sans-serif;
  font-size: 1.75rem;
  line-height: 2rem;
  letter-spacing: -0.035rem;
}

.symmetric-padding-none {
  padding: 0px;
}
.symmetric-padding-small {
  padding: 64px 24px;
  
  @media (max-width: $mobile-breakpoint) {
    padding: 64px 24px;
  }
}
.symmetric-padding-medium {
  padding: 72px 24px;

  @media (max-width: $mobile-breakpoint) {
    padding: 32px 24px;
  }
}
.symmetric-padding-large {
  padding: 80px 24px;

  @media (max-width: $mobile-breakpoint) {
    padding: 40px 24px;
  }
}
.symmetric-padding-xl {
  padding: 104px 24px;

  @media (max-width: $mobile-breakpoint) {
    padding: 40px 24px;
  }
}

.section-guest-contact {
  background-image: url('~@public-web/assets/images/contact-us.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-origin: content-box;

  @media (max-width: $mobile-breakpoint) {
    background-image: none;
  }
}
