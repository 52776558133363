.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    width: 100%;
    overflow: hidden;

    @media (max-width: 750px) {
        flex-direction: column;
        gap: 30px;
      }

    .contact-image {
        background-repeat: no-repeat;
        background-size: 170%;
        background-position: 45% 55%;
        min-width:200px;
        width: 393px;  
        height: 100%;
        object-fit: cover;

        @media (max-width: 750px) and (min-width: $mobile-breakpoint) {
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: 45% 55%;
            width: 100%;  
            height: 450px;
            object-fit: cover;
        }

        @media (max-width: $mobile-breakpoint) {
            background-size: 100%;
            background-position: 45% 55%; 
            height: 312px;
            object-fit: cover;
            margin-top: -28px;
        }
    }

    .contact-description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 611px;
        gap: 20px;

        @media (max-width: $mobile-breakpoint) {
            flex-direction: column;
            max-width: 100%;
            gap: 4px;

            button {
                margin-top: 16px;
            }
          }

        .section-title {
            text-align: left;
            min-width: 0px;
        }

            .mobile-product {
                text-align: center;
            }
    }
}
