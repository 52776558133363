.contact-us-form {
  select:focus {
    border: 2px solid $advanceBlue400 !important; /* Change the border color and style as needed */
    outline: none; /* Remove the default browser outline */

  }
  .input-row {
    display: flex;
  
    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
    }
  
    .input-col {
      flex: 1;
    }

    .fixed-width {
      flex-grow: unset;
    }
  }

  .button-container button {
    @media (max-width: $mobile-breakpoint) {
      box-sizing: border-box;
      width: 100%;
    }
  }

}
