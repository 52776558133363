.hero-banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  min-height: 545px;

  @media (max-width: $mobile-breakpoint) {
    padding: 40px 24px 0 24px;
    gap: 24px;
    min-height: 0;
  }

  .hero-banner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: $max-container-width;
    overflow-x: visible;
    width: 100%;
    gap: 16px;

    @media (max-width: 750px) {
      padding-top: 24px;
      flex-direction: column;
    }

    img {
      flex-shrink: 2;
      max-height: 552px;
      object-fit: scale-down;

      @media (max-width: 1296px) {
        overflow-x: hidden;
      }

      @media (max-width: $max-container-width) {
        min-width: 200px;
      }

      @media (max-width: $mobile-breakpoint) {
        max-height: 234px;
        max-width: 293px;
        margin: 47px 0;
      }
    }

    .hero-banner-text {
      display:flex;
      flex-direction: column;
      gap: 16px;
      width: 602px;
      flex-shrink: 0;

      @media (max-width: $max-container-width) {
        flex-shrink: 2;
        min-width: 200px;
      }

      @media (max-width: 750px) {
        max-width: 100%;
        flex-direction: column;
      }

      @media (max-width: $mobile-breakpoint) {
        gap: 12px;
      }
    }

    .landing-text {
      width: 546px;
    }

    .mirrored {
      transform: scaleX(-1);
    }
  }
}
