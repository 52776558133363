.search-header--main {
  display: flex;
  justify-content: center;

  .search-input-container {
    width: 70%;

    @media (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }
}

.search-header--inner {
  display: flex;

  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
  }

  .search-title-container {
    flex: 1;
  }

  .search-input-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 544px;

    @media (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }
}
