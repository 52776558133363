.breadcrumb {
  display: flex;
  margin-block: unset;
  padding-inline-start: 0;

  li {
    display: inline;

    div {
      align-items: center;
      display: flex;
    }
  }
}
