.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .mobile-subheader {
    text-align: center;
    font-family: 'NotoSansBold', $webfonts, sans-serif;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.035rem;
  }

  .logo-group {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: $max-container-width;
  }

  .logo-group-regular {
    gap: 24px;

    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
      width: 100%;
      gap: 24px;
    }
  }
  .logo-group-small {
    gap: 16px;

    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
      width: 100%;
      gap: 16px;
    }
  }

  .logo-quote {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    align-self: stretch;

    @media (max-width: $mobile-breakpoint) {
      text-align: center;
    }
  }

  .logo-quote-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    gap: 12px;
    max-width: 243px;

    @media (max-width: $mobile-breakpoint) {
      max-width: 100%;
      align-items: center;
      gap: 20px;

      button {
        padding: 4px 16px;
        margin-bottom: 8px;
      }
    }
  }

  .logo-regular {
    display: grid;
    place-content: center;
    place-items: center;
    width: 243px;
    height: 65px;
    border-radius: 8px;
    border: 2px solid $lightGray300;
    background-color: $white;
    padding: 6px;


    @media (max-width: $mobile-breakpoint) {
      width: 100%;
      align-self: stretch;
    }

    img {
      height: 48px;
      width: 100%;
      object-fit: contain;

      @media (max-width: $mobile-breakpoint) {
        height: 44px;
      }
    }
  }

  .logo-small {
    display: flex;
    flex-direction: row;
    place-content: center;
    place-items: center;
    width: 220px;
    height: 65px;
    border-radius: 8px;
    border: 2px solid $lightGray300;
    background-color: $white;


    @media (max-width: $mobile-breakpoint) {
      width: 100%;
      align-self: stretch;
    }

    img {
      height: 48px;
      width: 100%;

      @media (max-width: $mobile-breakpoint) {
        height: 44px;
      }
    }
  }
}

.logo-container-regular {
  gap: 48px;
}

.logo-quote-container-foxmont {
  background-color: $gray600 !important;
}

.logo-quote-container-nextbillion {
  background-color: #264259 !important;
}

.logo-foxmont {
  max-height: 42px;
}

.logo-accion {
  max-height: 27px;
}

.logo-integra {
  max-height: 23px;
}

// nextBillion logo
.logo-XvXOt{
  height: 150px !important;
}
